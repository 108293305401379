import React, { useCallback, useState, useRef, useContext, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { rtkQueryApi } from 'src/redux/features/api/rtkQueryApi';
import CalendarForm, { getFormatCalendarData } from '@shared/components/CalendarForm/CalendarForm';
import { Label, Modal, StyledModalRowEnd, StyledModalRowStart, Textarea } from '@shared/components';
import { StyledAddFilesButton, StyledProfileAttachList } from '@components/employees/employees-form/styles';
import { url_employee_create_send_data, url_send_edited_employee } from 'src/settings/base-url';
import SelectMulty from 'src/components/form/select/SelectMulty';
import { getUsers } from 'src/redux/features/usersSlice';
import { useEventTriggerOnEscPress, randomSequence } from 'src/utilize/helper-functions';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import DropFilesWrapper from 'src/components/files/DropFilesWrapper';
import FileToUpload from 'src/components/files/FileToUpload';
import SnackbarContext from 'src/contexts/SnackbarContext';
import AttachedFile from 'src/components/files/AttachedFile';
import RadioItems from 'src/components/form/RadioItems';
import EmployeesFormCol from 'src/components/employees/employees-form/EmployeesFormCol';

import EmployeesTextarea from './EmployeesTextarea';

const schemaCreateEmployees = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  contacts_email: yup.string().email(),
  password: yup.string().required(),
  date_start: yup.date().required(),
});

// форматируем данные для селекта, выводит только title, title и имя, title и имя и фамилию и тд
const formatSelectOptions = (arr, value, value2, value3) => {
  return arr.map((el) => {
    return {
      label: (el[`${value}`] || '') + ' ' + (el[`${value2}`] || '') + (el[`${value3}`] || ''),
      value: el.id,
    };
  });
};

const EmployeesFormBody = ({
  action,
  position,
  password,
  birthday,
  date_start,
  head_department,
  inputs,
  department,
  other_contacts,
  address,
  passport_visible,
  passport_hidden,
  rights,
  employeeData,
  onCloseModal,
}) => {
  const { t } = useTranslation();

  // форматируем для мультиселекта с правами или для select списка
  const formatOptions = (arr) => {
    return arr
      .filter((right) => right !== 'team_search_view' && right !== 'team_search_edit') // эти два права пока нигде не используются
      .map((el) => {
        return {
          label: t(`common.rights.${el}`),
          value: el,
        };
      });
  };
  // устанавливаем значение полей прав, массив должен быть одним и тем же, мы  фильтруем уже выбранные ранее поля
  const getPrevMultySelect = (common, own) => {
    // own- права, доступные сотруднику, common - общий массив всех прав
    var array3 = common.filter(function (obj) {
      return own.indexOf(obj) >= 0;
    });
    return formatOptions(array3);
  };

  const getDefValues = (action, employeeData, allRights) => {
    const {
      first_name,
      email,
      date_start,
      head_last_name,
      head_first_name,
      position,
      department,
      head_id,
      department_id,
      position_id,
      passport_hidden,
      passport_visible,
    } = employeeData?.employee[0] || {};
    // эти поля долдны совпадать с настройками в пропсах формы

    const defValues = {
      first_name: '',
      last_name: '',
      sex: '',
      birthday: '',
      email: '',
      contacts_phone: '',
      password: '',
      contacts_address: '',
      passport_visible: '',
      passport_hidden: '',
      contacts_other: '',
      date_start: '',
      position_id: '',
      head_id: '',
      department_id: '',
      rights: '',
    };

    if (action === 'edit') {
      defValues.first_name = first_name;
      defValues.email = email;
      defValues.password = '******';
      defValues.date_start = date_start;

      defValues.position_id = {
        label: position || '',
        value: position_id || '',
      };

      // глава отдела
      // если редактируем и если глава отдела назначен
      defValues.head_id = {
        label: head_first_name ? `${head_first_name} ${head_last_name || ''}` : ' не назначен',
        // в value даем id руководителя, которые ждет от нас сервер
        value: head_first_name ? head_id : '',
      };

      defValues.department_id = {
        label: department || '',
        value: department_id || '',
      };
      //  права
      defValues.rights = getPrevMultySelect(allRights, employeeData.employee_rights);
      defValues.passport_visible = passport_visible;
      defValues.passport_hidden = passport_hidden;
    }

    return defValues;
  };

  const {
    register,
    control,
    formState: { isValid, isDirty },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaCreateEmployees),
    mode: 'onChange',
    defaultValues: getDefValues(action, employeeData, rights.options),
  });

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const promptModalClose = useCallback(() => {
    if (isDirty) setModalClosePrompt(true);
    else onCloseModal();
  }, [isDirty, onCloseModal]);

  useEventTriggerOnEscPress(promptModalClose);

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState();

  const [filesToUpload, setFilesToUpload] = useState([]);
  const uploadedFilesLocalIds = useRef([]);

  const [fileUploadPercentages, setFileUploadPercentages] = useState({});
  const abortFileSubmitController = useRef();
  const currentlyUploadingFile = useRef();

  const handleFilesSelect = (files) => {
    if (isSubmitting) return;

    const newFilesPercentages = {};
    for (let i = 0; i < files?.length; i++) {
      files[i].localId = randomSequence();
      //
      newFilesPercentages[files[i].localId] = 0;
    }
    setFileUploadPercentages((f) => ({ ...f, ...newFilesPercentages }));
    setFilesToUpload((f) => [...f, ...files]);
  };

  const { showSnackbar } = useContext(SnackbarContext);

  const [newEmployeeId, setNewEmployeeId] = useState();

  useEffect(() => {
    if (newEmployeeId && !currentlyUploadingFile.current) {
      const fileToUpload = filesToUpload.find((file) => !file.isUploaded && !file.errorUploading);
      if (fileToUpload) {
        const upload = async () => {
          const formData = new FormData();
          formData.append('files', fileToUpload, fileToUpload.name);
          formData.append('employee_id', newEmployeeId);
          let progressPercentage = 0;
          abortFileSubmitController.current = new AbortController();
          currentlyUploadingFile.current = fileToUpload;
          try {
            await axios
              .post('/api/employees/files/add', formData, {
                onUploadProgress: (progressEvent) => {
                  const currProgress = parseInt(Math.round(progressEvent.loaded * 100) / progressEvent.total);
                  if (currProgress !== progressPercentage) {
                    progressPercentage = currProgress;
                    //
                    setFileUploadPercentages((f) => ({
                      ...f,
                      [fileToUpload.localId]: currProgress,
                    }));
                    // uploadProgress(fileWithId.localId, currProgress);
                  }
                },
                signal: abortFileSubmitController.current.signal,
              })
              .then(() => {
                uploadedFilesLocalIds.current.push(fileToUpload.localId);

                currentlyUploadingFile.current = null;
                abortFileSubmitController.current = null;
                setFilesToUpload((files) =>
                  files.map((file) => {
                    if (file.localId === fileToUpload.localId) {
                      file.isUploaded = true;
                    }
                    return file;
                  }),
                );
              });
          } catch (e) {
            currentlyUploadingFile.current = null;
            abortFileSubmitController.current = null;
            setFilesToUpload((files) =>
              files.map((file) => {
                if (file.localId === fileToUpload.localId) {
                  file.errorUploading = true;
                }
                return file;
              }),
            );
            if (e?.message !== 'canceled') {
              showSnackbar(`Ошибка при загрузке файла "${fileToUpload.name}"`);
            }
          } finally {
          }
        };
        setTimeout(upload, 10);
      } else {
        showSnackbar('Загрузка файлов обработана', 'success');
        setIsSubmitting(false);
        dispatch(getUsers());
        onCloseModal();
      }
    }
  }, [newEmployeeId, filesToUpload, dispatch, onCloseModal, showSnackbar]);

  const errorHandler = useCallback((err) => {
    setIsSubmitting(false);
    let errorMessage = 'Ошибка при добавлении сотрудника';
    if (err.response?.status === 409 && err.response?.data?.message === 'Email already exists') {
      errorMessage = 'Пользователь с таким e-mail адресом уже существует';
    }

    showSnackbar(errorMessage);
  }, []);

  const onSubmitCreate = (data) => {
    const { rights, department_id, head_id, position_id, ...row } = data;

    let body = {
      row: {
        ...row,
        head_id: head_id?.value || null,
        position_id: position_id?.value || null,
        birthday: data.birthday ? getFormatCalendarData(data.birthday) : null,
        date_start: data.date_start ? getFormatCalendarData(data.date_start) : null,
        department_id: department_id?.value || null,
        company_id: 1,
      },
      rights: data?.rights ? data.rights.map((a) => a.value) : [],
    };

    body.row = cleanFormData(body.row);
    setIsSubmitting(true);
    axios.post(url_employee_create_send_data, body).then(async (res) => {
      const newEmployeeId = res.data.result.insertId;

      if (filesToUpload?.length) {
        showSnackbar('Сотрудник добавлен, отправляются файлы...', 'success');
        setNewEmployeeId(newEmployeeId);
      } else {
        showSnackbar('Сотрудник добавлен', 'success');
        setIsSubmitting(false);
        dispatch(getUsers());
        onCloseModal();
      }
      dispatch(rtkQueryApi.util.invalidateTags(['SubordinateEmployees']));
    }, errorHandler);
  };

  //  удаляем все свойства объекта где задан null или пустая строка
  const cleanFormData = (obj) => {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj;
  };

  const filesToRemoveFromServer = useRef([]);
  const [existingFiles, setExistingFiles] = useState(employeeData?.employee_files);

  const removeExistingFile = (index) => {
    filesToRemoveFromServer.current.push(existingFiles[index].file);
    setExistingFiles((files) => files.filter((file, i) => i !== index));
  };

  //  отправляем форму если редактируем
  const onSubmitEdit = (data) => {
    const { rights, ...row } = data;
    const { department_id, head_id, position_id, date_start, birthday } = data;
    // если в календаре ничего не меняем, отправляем пред значение дня рождения и даты выхода на работу
    let prevDateStart = employeeData?.employee[0].date_start || null;
    let prevBirthday = employeeData?.employee[0].birthday || null;

    let body = {
      row: {
        ...row,
        department_id: department_id?.value || null,
        head_id: head_id?.value || null,
        position_id: position_id?.value || null,
        birthday: birthday ? getFormatCalendarData(birthday) : prevBirthday,
        date_start: date_start ? getFormatCalendarData(date_start) : prevDateStart,
        company_id: 1,
      },
      rights: data.rights.map((a) => a.value),
      employee_id: employeeData.employee[0].id,
    };
    if (data.password === '******') {
      delete body.row.password;
    }
    //
    // body.row = cleanFormData(body.row);
    setIsSubmitting(true);
    if (filesToRemoveFromServer.current?.length) {
      axios.patch('/api/employees/files/remove', {
        employee_id: employeeData.employee[0].id,
        files: filesToRemoveFromServer.current,
      });
    }

    axios.put(url_send_edited_employee, body).then(() => {
      if (filesToUpload?.length) {
        setNewEmployeeId(employeeData.employee[0].id);
      } else {
        dispatch(getUsers());
        onCloseModal();
      }
      dispatch(rtkQueryApi.util.invalidateTags(['SubordinateEmployees']));
    }, errorHandler);
  };

  const openInputFiles = () => inputFilesRef.current.click();

  const inputFilesRef = useRef();

  const removeFile = (fileToRemove) => {
    if (currentlyUploadingFile.current?.localId === fileToRemove.localId) {
      abortFileSubmitController.current.abort();
      currentlyUploadingFile.current = null;
      setFilesToUpload((files) => files.filter((f) => f.localId !== fileToRemove.localId));
    } else {
      setFilesToUpload((files) => files.filter((file) => file.localId !== fileToRemove.localId));
    }
  };

  const departmentHeadsOptions = useMemo(() => {
    const headsOptions = formatSelectOptions(head_department.options, 'last_name', 'first_name');
    return [{ label: 'Руководителя нет', value: null }, ...headsOptions];
  }, [head_department.options]);

  return (
    <>
      <Modal
        title={action === 'edit' ? t('EmployeesForm.title_edit') : t('EmployeesForm.title_create')}
        onClose={promptModalClose}
        disabledSaveButton={!isValid || isSubmitting}
        onSave={handleSubmit(action === 'edit' ? onSubmitEdit : onSubmitCreate)}
        confirmButtonText={action === 'edit' ? t('FormButtons.save') : t('FormButtons.create')}
      >
        <DropFilesWrapper setFiles={handleFilesSelect} disabled={isSubmitting}>
          <form onSubmit={handleSubmit(action === 'edit' ? onSubmitEdit : onSubmitCreate)}>
            {Object.keys(inputs).map((input, i) => (
              <StyledModalRowStart key={i}>
                {inputs[input].map((el, ind) => {
                  if (el?.type === 'radio') {
                    return (
                      <React.Fragment key={ind}>
                        <div key={ind}>
                          <RadioItems register={register} settings={el} />
                        </div>
                        <CalendarForm
                          isRequired={el.isRequired}
                          data={birthday}
                          control={control}
                          prev={employeeData?.employee[0].birthday}
                        />
                      </React.Fragment>
                    );
                  }
                  return (
                    <EmployeesFormCol
                      setValue={setValue}
                      prev={el.prev}
                      key={ind + 200}
                      input={el}
                      control={control}
                      settings={el}
                      name={el.name}
                      isRequred={el.isRequired}
                    />
                  );
                })}
              </StyledModalRowStart>
            ))}

            {/* другие контакты */}
            <EmployeesTextarea
              setValue={setValue}
              prev={employeeData?.employee[0].contacts_other}
              control={control}
              settings={other_contacts}
              name={other_contacts.name} // name нужно для настройки react hook form
            />

            {/* адрес  */}
            <EmployeesTextarea
              prev={employeeData?.employee[0].contacts_address}
              setValue={setValue}
              control={control}
              settings={address}
              name={address.name} // name нужно для настройки react hook form
            />
            {/* паспортные данные (открыте для всех)  */}
            <div>
              <Label>Паспортные данные (открыте для всех)</Label>
              <Controller
                control={control}
                name={passport_visible.name}
                render={({ field }) => {
                  return <Textarea value={field.value} onChange={field.onChange} />;
                }}
              />
            </div>
            <div>
              <Label>Паспортные данные (скрытые от всех)</Label>
              <Controller
                control={control}
                name={passport_hidden.name}
                render={({ field }) => {
                  return <Textarea value={field.value} onChange={field.onChange} />;
                }}
              />
            </div>

            {/* Должность */}
            <div>
              <Label>{position.label}</Label>
              <Controller
                name={position.name}
                control={control}
                render={({ field }) => {
                  return (
                    <Select {...field} defaultOptions={true} options={formatSelectOptions(position.options, 'title')} />
                  );
                }}
              />
            </div>

            {/* пароль */}
            <EmployeesTextarea
              setValue={setValue}
              prev={employeeData?.employee[0].password}
              control={control}
              settings={password}
              name={password.name}
            />
            {/* Отдел  */}
            <div>
              <Label>{department.label}</Label>
              <Controller
                name={department.name}
                control={control}
                render={({ field }) => <Select {...field} options={formatSelectOptions(department.options, 'title')} />}
              />
            </div>

            {/* Руководитель */}
            <div>
              <Label>{head_department.label}</Label>
              <Controller
                name={head_department.name}
                control={control}
                render={({ field }) => <Select {...field} options={departmentHeadsOptions} />}
              />
            </div>

            {/* Календарь  */}

            <StyledModalRowEnd>
              <CalendarForm data={date_start} control={control} prev={employeeData?.employee[0].date_start} />
            </StyledModalRowEnd>
            <StyledModalRowEnd>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={inputFilesRef}
                onChange={(e) => handleFilesSelect(e.target.files)}
                multiple
              />
              <StyledAddFilesButton type="button" onClick={openInputFiles} disabled={isSubmitting}>
                Добавить файлы
              </StyledAddFilesButton>
            </StyledModalRowEnd>

            {existingFiles?.length > 0 && (
              <StyledProfileAttachList>
                {Array.isArray(existingFiles) &&
                  existingFiles?.map((file, i) => {
                    const url = `/employees/files/${employeeData.employee[0].id}/${file.file}`;
                    return (
                      <AttachedFile
                        fileUrl={url}
                        fileName={file.file}
                        key={i}
                        removeFile={() => removeExistingFile(i)}
                      />
                    );
                  })}
              </StyledProfileAttachList>
            )}

            {/* ранее загруженные файлы, при редактировании сотрудника */}
            {filesToUpload.length > 0 && (
              <StyledProfileAttachList>
                {filesToUpload?.map((file, i) => (
                  <FileToUpload
                    file={file}
                    progressPercent={fileUploadPercentages[file.localId]}
                    key={i}
                    index={i}
                    removeFile={removeFile}
                    isUploaded={file.isUploaded}
                    removable={!file.isUploaded}
                    errorUploading={file.errorUploading}
                  />
                ))}
              </StyledProfileAttachList>
            )}
            {/* права */}
            <SelectMulty item={rights} control={control} options={formatOptions([...rights.options])} />
          </form>
        </DropFilesWrapper>
      </Modal>

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={onCloseModal}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </>
  );
};

export default EmployeesFormBody;
