import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { logoutAction } from './usersSlice';

const initialState = {
  newNotification: null,
  isLoading: false,
  error: null,
  unreadNotifications: [],
};

export const getNotificationsOnLoad = createAsyncThunk(
  'notifications/getNotificationsOnLoad',
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get('/api/notifications_for_me');
      const readNotes = [];
      const notifications = response.data.notifications.filter((note) => {
        if (note.data_type === 21 || note.date_type === 22 || note.date_type === 23) {
          readNotes.push(note.id);
          return false;
        }
        return true;
      });

      if (readNotes.length) payload.readNotification(readNotes);
      return notifications;
    } catch (e) {
      return thunkAPI.rejectWithValue('Ошибка при загрузке уведомлений');
    }
  },
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNewNotification: (state, { payload }) => {
      state.newNotification = payload;
    },
    addUnreadNotification: (state, { payload }) => {
      // отфильтровать уведомления, уже имеющиеся в списке непрочитанных
      const newUnreadNotifications = payload.filter((newNotification) => {
        if (state.unreadNotifications.find((unreadNotification) => unreadNotification.id === newNotification.id)) {
          return false;
        }
        return true;
      });
      if (newUnreadNotifications.length) {
        state.unreadNotifications = newUnreadNotifications.concat(state.unreadNotifications);
      }

      state.newNotification = null;
    },
    clearNewNotification: (state) => {
      state.newNotification = null;
    },
    removeNotification: (state, { payload }) => {
      state.unreadNotifications = state.unreadNotifications.filter((n) => n.id !== payload);
    },
    clearAllNotifications: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [getNotificationsOnLoad.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getNotificationsOnLoad.fulfilled]: (state, { payload }) => {
      state.newNotification = payload;
      state.isLoading = false;
      state.error = null;
    },
    [getNotificationsOnLoad.rejected]: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    [logoutAction]: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  addNewNotification,
  addUnreadNotification,
  removeNotification,
  clearNewNotification,
  clearAllNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
