import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {
  StyledAsideBackwardButton,
  StyledAsideBody,
  StyledAsideHeader,
  StyledAsideLink,
  StyledAsideParentTask,
  StyledAsideSubtitle,
  StyledAsideTitle,
} from '@components/chat/side-menu/styles';
import iconSearch from '@assets/images/icons/search.svg';
import { IconButton } from '@shared/components';
import Search from '@shared/components/Search/Search';
import iconClose from '@assets/images/icons/close.svg';

import Preloader from '../../preloaders/Preloader';
import UserProfileModalContainer from '../../../windows/profile/UserProfileModalContainer';

import ProjectTree from './ProjectTree';
import { renderProjectOrTaskName } from './SideMenu';
import MessageAside from './sideMenuComponents/MessageAside';

const SearchSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();
  const [projectTree, showProjectTree] = useState(false);
  const [dataType, setDataType] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, showUserProfile] = useState();

  const [projectOrTaskToSearch, setProjectOrTaskToSearch] = useState(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const methods = useForm();

  // reset данных пр изменении id в url
  useEffect(() => {
    if (!projectId && !taskId) return;
    setPanelData(null);
  }, [projectId, taskId]);

  useEffect(() => {
    if (taskId && dataType !== 'task') setDataType('task');
    if (!taskId && dataType !== 'project') setDataType('project');
  }, [taskId, dataType]);

  const searchMessages = async ({ search_word }) => {
    if (search_word && dataType && projectData) {
      setPanelData(null);
      setIsLoading(true);
      if (errorLoading) setErrorLoading(false);

      let searchDataType, dataId;
      if (projectOrTaskToSearch) {
        searchDataType = projectOrTaskToSearch.dataType;
        dataId = projectOrTaskToSearch.dataId;
      } else {
        searchDataType = dataType;
        dataId = projectData.id;
      }

      try {
        const response = await axios.get(`/api/sidebar_search/${searchDataType}/${dataId}/${search_word}`);
        const chat_messages = response.data.chat_messages;
        setIsLoading(false);
        setPanelData({
          messages: chat_messages,
          id: projectData.id,
        });
      } catch (e) {
        setIsLoading(false);
        setErrorLoading(true);
      }
    }
  };

  // reset данных при закрытии
  useEffect(() => {
    if (sideMenu !== 'search' && (panelData || projectOrTaskToSearch)) {
      setTimeout(() => {
        setPanelData(null);
        setProjectOrTaskToSearch(null);
        methods.reset();
      }, 1000);
    }
  }, [sideMenu, panelData, projectOrTaskToSearch, methods]);

  const showRelevantTaskInfo = useCallback(
    (dataType, dataId, title) => {
      const search_word = methods.getValues('search_word');
      setPanelData(null);
      showProjectTree(false);
      setErrorLoading(false);
      setProjectOrTaskToSearch({
        dataType,
        dataId,
        title,
      });
      if (search_word) {
        axios
          .get(`/api/sidebar_search/${dataType}/${dataId}/${search_word}`)
          .then((response) => {
            const chat_messages = response.data.chat_messages;
            setPanelData({
              messages: chat_messages,
              id: dataId,
            });

            setIsLoading(false);
            setDataType(dataType);
            setErrorLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setErrorLoading(true);
          });
      }
    },
    [methods],
  );

  return (
    <>
      {userProfile && <UserProfileModalContainer profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <>
        <StyledAsideHeader>
          <StyledAsideTitle icon={iconSearch} iconsize={20}>
            {t('common.search')}
          </StyledAsideTitle>
          <IconButton
            icon={iconClose}
            size={27}
            onClick={() => {
              openSideMenu(null);
            }}
          />
        </StyledAsideHeader>
        <StyledAsideBody>
          {projectTree ? (
            <ProjectTree
              update={sideMenu === 'search'}
              showRelevantTaskInfo={showRelevantTaskInfo}
              showProjectTree={showProjectTree}
            />
          ) : (
            <>
              <StyledAsideBackwardButton onClick={() => showProjectTree(true)}>
                Структура проекта
              </StyledAsideBackwardButton>
              <StyledAsideParentTask>
                {renderProjectOrTaskName(projectOrTaskToSearch, taskId, projectOrTaskToSearch?.dataType, projectData)}
              </StyledAsideParentTask>

              {projectOrTaskToSearch?.dataType === 'project' && taskId && (
                <StyledAsideLink to={`/projects/${projectId}`}>{t('Project.open_project')}</StyledAsideLink>
              )}

              {projectOrTaskToSearch?.dataType === 'task' && projectOrTaskToSearch?.dataId !== +taskId && (
                <StyledAsideLink to={`/projects/${projectId}/tasks/${projectOrTaskToSearch.dataId}`}>
                  {t('Project.open_task')}
                </StyledAsideLink>
              )}

              <Search
                register={{ ...methods.register('search_word') }}
                onSubmit={methods.handleSubmit(searchMessages)}
                placeholder="Поиск"
              />

              {isLoading && <Preloader />}

              {errorLoading && <div>{t('ErrorMessages.default')}</div>}

              {panelData && <StyledAsideSubtitle>{t('common.search_results')}</StyledAsideSubtitle>}

              {panelData?.messages?.length > 0 && (
                <>
                  {panelData.messages.map((chat_message, i) => {
                    return (
                      <MessageAside
                        chat_message={chat_message}
                        key={i}
                        showUserProfile={showUserProfile}
                        dataType={projectOrTaskToSearch?.dataType || dataType}
                        dataId={projectOrTaskToSearch?.dataId || projectData?.id}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </StyledAsideBody>
      </>
    </>
  );
};

export default SearchSidePanel;
