import { SingleSelect } from '@shared/components/Select/Select';
import { useController } from 'react-hook-form';

const options = [
  {
    label: 'Проект, виртуальный офис, раздел или просьба по названию и описанию',
    value: 1,
  },
  {
    label: 'Сообщение в проекте, виртуальном офисе, разделе или просьбе',
    value: 2,
  },
  {
    label: 'Файл или папка в хранилище',
    value: 3,
  },
  {
    label: 'Файл в сообщениях чатов',
    value: 4,
  },
];

export const SearchTypeSelect = ({ control, resetField }) => {
  const { field } = useController({ control, name: 'searchType' });

  return (
    <SingleSelect
      placeholder="Выберите вид поиска"
      options={options}
      onChange={(option) => resetField('searchType', { keepDirty: false, defaultValue: option.value })}
      defaultValue={options.filter((option) => option.value === field.value)}
    />
  );
};
