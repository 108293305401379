import { useEffect, useState, memo, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import HTMLReactParser from 'html-react-parser';
import {
  StyledAsideBackwardButton,
  StyledAsideBody,
  StyledAsideHeader,
  StyledAsideLink,
  StyledAsideParentTask,
  StyledAsideSubtitle,
  StyledAsideTitle,
  StyledResult,
  StyledResultHeader,
  StyledResultItem,
  StyledResultState,
  StyledResultTitle,
  StyledResultType,
} from '@components/chat/side-menu/styles';
import iconInfo from '@assets/images/icons/icon-info.svg';

import { IconButton } from '@shared/components';
import iconClose from '@assets/images/icons/close.svg';

import useAuth from 'src/hooks/useAuth';
import UserProfileModalContainer from 'src/windows/profile/UserProfileModalContainer';
import { selectGuestProjectRights } from 'src/redux/features/projectsSlice';
import Preloader from 'src/components/preloaders/Preloader';

import ProjectTree from './ProjectTree';
import SideMenuMembers from './sideMenuComponents/SideMenuMembers';
import SideMenuTags from './sideMenuComponents/SideMenuTags';
import SideMenuProjectFiles from './sideMenuComponents/SideMenuProjectFiles';

import { renderProjectOrTaskName } from './SideMenu';
import AsideUserCard from './sideMenuComponents/AsideUserCard';

export default memo(InfoSidePanel);

function InfoSidePanel({ sideMenu, openSideMenu, projectData }) {
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();

  const [dataType, setDataType] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [userProfile, showUserProfile] = useState();
  const [projectTree, showProjectTree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const isRequesting = useRef(false);

  // reset данных сайд панели, при изменений id в адресной строке
  useEffect(() => {
    if (!taskId && !projectId) return;
    setPanelData(null);
    setErrorLoading(false);
    showProjectTree(false);
    setSidebarDataType(null);
  }, [taskId, projectId]);

  // каждый раз, когда нажимается кнопка открытия панели, запрашиваются данные по текущему проекту/таску
  useEffect(() => {
    if (!projectId && !taskId) return;
    if (!isRequesting.current && sideMenu === 'info' && !errorLoading) {
      const url = `/api/sidebar_info/${taskId ? 'task/' + taskId : 'project/' + projectId}`;

      setIsLoading(true);
      isRequesting.current = true;
      axios
        .get(url)
        .then((response) => {
          if (dataType === 'project') {
            setPanelData(response.data.project);
          } else {
            setPanelData(response.data.task);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setErrorLoading(true);
        })
        .finally(() => (isRequesting.current = false));
    }
  }, [sideMenu, dataType, projectId, taskId]);

  // каждый раз, когда нажимается кнопка закрытия панели, производится reset данных
  useEffect(() => {
    if (sideMenu !== 'info' && panelData) {
      setTimeout(() => {
        setPanelData(null);
        setErrorLoading(false);
        setDataType(null);
        showProjectTree(false);
        setSidebarDataType(null);
      }, 1000);
    }
  }, [sideMenu, panelData]);

  useEffect(() => {
    if (taskId && dataType !== 'task') setDataType('task');
    if (!taskId && dataType !== 'project') setDataType('project');
  }, [taskId, dataType]);

  const [sidebarDataType, setSidebarDataType] = useState();

  const showRelevantTaskInfo = useCallback((dataType, dataId) => {
    setPanelData(null);
    showProjectTree(false);
    setErrorLoading(false);
    setIsLoading(true);
    axios
      .get(`/api/sidebar_info/${dataType}/${dataId}`)
      .then((response) => {
        if (dataType === 'project') {
          setPanelData(response.data.project);
        } else {
          setPanelData(response.data.task);
        }
        setIsLoading(false);
        setSidebarDataType(dataType);
      })
      .catch(() => {
        setPanelData({});
        setIsLoading(false);
        setErrorLoading(true);
      });
  }, []);

  const renderFiles = () => {
    if (panelData) {
      let files;
      if (panelData.project_files) {
        files = panelData.project_files;
      } else {
        files = panelData.task_files;
      }
      return <SideMenuProjectFiles files={files} dataType={dataType} dataId={panelData.id} />;
    }
  };

  const renderGoals = (goals) => {
    if (!goals) return null;
    return (
      <>
        <StyledAsideSubtitle>{t('common.result')}</StyledAsideSubtitle>

        <StyledResult>
          {goals.map((goal) => (
            <StyledResultItem key={goal.id}>
              <StyledResultHeader>
                <StyledResultTitle>{goal.description}</StyledResultTitle>
                <StyledResultType>{t(`Project.result.${goal.type}`)}</StyledResultType>
              </StyledResultHeader>
              <StyledResultState state="done">Исполнено</StyledResultState>
            </StyledResultItem>
          ))}
        </StyledResult>
      </>
    );
  };

  const auth = useAuth();
  const guestRights = useSelector(selectGuestProjectRights(sidebarDataType || dataType, panelData?.id));

  return (
    <>
      {userProfile && <UserProfileModalContainer profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <>
        <StyledAsideHeader>
          <StyledAsideTitle icon={iconInfo} iconsize={22}>
            {t('common.info')}
          </StyledAsideTitle>
          <IconButton
            icon={iconClose}
            size={27}
            onClick={() => {
              setTimeout(() => {
                setPanelData(null);
                setDataType(null);
              }, 1000);
              openSideMenu(null);
            }}
          />
        </StyledAsideHeader>

        <StyledAsideBody>
          {isLoading && <Preloader />}
          {errorLoading && <div>{t('ErrorMessages.default')}</div>}
          {panelData && (
            <>
              {projectTree ? (
                <ProjectTree
                  update={sideMenu === 'info'}
                  showRelevantTaskInfo={showRelevantTaskInfo}
                  showProjectTree={showProjectTree}
                />
              ) : (
                <>
                  {(!auth?.user?.is_guest || guestRights?.includes('access_children')) && (
                    <StyledAsideBackwardButton onClick={() => showProjectTree(true)}>
                      Структура проекта
                    </StyledAsideBackwardButton>
                  )}
                  <StyledAsideParentTask>
                    {renderProjectOrTaskName(panelData, taskId, sidebarDataType, projectData)}
                  </StyledAsideParentTask>
                  {sidebarDataType === 'project' && taskId && (
                    <StyledAsideLink to={`/projects/${projectId}`}>{t('Project.open_project')}</StyledAsideLink>
                  )}
                  {sidebarDataType === 'task' && panelData && panelData.id !== +taskId && (
                    <StyledAsideLink to={`/projects/${projectId}/tasks/${panelData.id}`}>
                      {t('Project.open_task')}
                    </StyledAsideLink>
                  )}
                  {!!panelData.description?.length && (
                    <>
                      <StyledAsideSubtitle>{t('Project.description')}</StyledAsideSubtitle>

                      {typeof panelData.description === 'string' ? HTMLReactParser(panelData.description) : ''}
                    </>
                  )}
                  {renderFiles()}

                  {!!(panelData.project_goals?.length || panelData.task_goals?.length) &&
                    renderGoals(panelData.project_goals || panelData.task_goals)}
                  <StyledAsideSubtitle>{t('Project.created_by')}</StyledAsideSubtitle>
                  <AsideUserCard
                    userId={panelData.creator_id}
                    lastName={panelData.creator_last_name}
                    firstName={panelData.creator_first_name}
                    showUserProfile={showUserProfile}
                  />

                  {panelData.responsible_id && (
                    <>
                      <StyledAsideSubtitle>{t('Project.responsible')}</StyledAsideSubtitle>
                      <AsideUserCard
                        userId={panelData.responsible_id}
                        lastName={panelData.responsible_last_name}
                        firstName={panelData.responsible_first_name}
                        showUserProfile={showUserProfile}
                      />
                    </>
                  )}

                  {panelData.executor_id && (
                    <>
                      <StyledAsideSubtitle>{t('Project.executor')}</StyledAsideSubtitle>
                      <AsideUserCard
                        userId={panelData.executor_id}
                        lastName={panelData.executor_last_name}
                        firstName={panelData.executor_first_name}
                        showUserProfile={showUserProfile}
                      />
                    </>
                  )}

                  <SideMenuMembers
                    members={panelData.project_members || panelData.task_members}
                    showUserProfile={showUserProfile}
                  />
                  <SideMenuTags tags={panelData.project_tags || panelData.task_tags} />
                </>
              )}
            </>
          )}
        </StyledAsideBody>
      </>
    </>
  );
}
