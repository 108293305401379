import { useCallback } from 'react';
import { useSocketContext } from 'src/contexts/SocketContext';
import { useMessageContext } from 'src/contexts/MessageContext';
import { formatDate } from '@shared/components/CalendarForm/CalendarForm';
import { Avatar } from '@shared/components/Avatar';

import {
  StyledActionHistory,
  StyledActionTime,
  StyledChatMessage,
  StyledUser,
  StyledUserName,
} from '@components/chat/message/styles';
import LocalDateTime from '@components/shared/LocalDateTime';

import MessageBox from './MessageBox';

const MessageContainer = (props) => {
  const { showPopover, editMessage, showUserProfile } = useMessageContext();
  const { sendResultFeedback } = useSocketContext();

  const formatNewDeadline = (additionalData) => {
    try {
      if (typeof additionalData === 'string') {
        const parsedData = JSON.parse(additionalData);
        return formatDate(parsedData.after) || 'Данные отсутствуют';
      } else if (additionalData?.after) {
        return formatDate(additionalData.after) || 'Данные отсутствуют';
      }
    } catch (error) {}
  };

  const formatUserLink = useCallback(
    (additionalData) => {
      try {
        if (typeof additionalData === 'string') {
          const parsedData = JSON.parse(additionalData);
          return (
            <StyledUserName
              onClick={() => {
                showUserProfile(parsedData.after.id);
              }}
            >
              {`${parsedData.after.last_name || ''} ${parsedData.after.first_name || ''}`}
            </StyledUserName>
          );
        } else if (additionalData?.after?.last_name || additionalData?.after?.first_name) {
          return (
            <StyledUserName
              onClick={() => {
                showUserProfile(additionalData.after.id);
              }}
            >
              {`${additionalData.after.last_name || ''} ${additionalData.after.first_name || ''}`}
            </StyledUserName>
          );
        }
      } catch (error) {}
    },
    [showUserProfile],
  );

  const actionHistoryList = {
    create_request: 'Просьба создана',
    change_request_executor: (
      <>Назначен новый исполнитель просьбы: {formatUserLink(props?.action_history?.additional_data)}</>
    ),
    change_request_deadline: <>Новый дедлайн просьбы: {formatNewDeadline(props?.action_history?.additional_data)}</>,
    request_take_on: 'Просьба принята в работу',
    request_on_clarification: 'Просьба отправлена на уточнение',
    request_under_review: 'Просьба выполнена, ждет проверки',
    request_accepting_redirect: (
      <>Результат исполнения просьбы проверит {formatUserLink(props?.action_history?.additional_data)}</>
    ),
    request_clarified: 'Просьба уточнена',
    request_returned: 'Просьба возвращена в работу',
    request_canceled: 'Просьба отменена',
    request_accepted: 'Выполнение просьбы проверено, все супер!',
    request_for_revision: 'Просьбу нужно доработать',
    change_request_executor_auto: 'Исполнитель просьбы изменен автоматически',
  };

  return (
    <>
      {props.action_history ? (
        <StyledChatMessage>
          <StyledActionHistory>
            <StyledUser
              onClick={() => {
                showUserProfile(props.action_history.employee_id);
              }}
            >
              <Avatar
                size="24"
                userId={props.action_history.employee_id}
                firstName={props.action_history.first_name}
                lastName={props.action_history.last_name}
                showProfileOnClick={!showUserProfile}
              />
              <StyledUserName>{`${props.action_history.last_name || ''} ${
                props.action_history.first_name || ''
              }`}</StyledUserName>
            </StyledUser>

            <StyledActionTime>
              <LocalDateTime dateTime={props.action_history.date_created} />
            </StyledActionTime>

            <p>{actionHistoryList[props.action_history.action_type]}</p>
          </StyledActionHistory>
        </StyledChatMessage>
      ) : (
        <MessageBox
          {...props}
          showPopover={showPopover}
          editMessage={editMessage}
          sendResultFeedback={sendResultFeedback}
          showUserProfile={showUserProfile}
          pinnedMessageType={props.pinnedMessageType}
        />
      )}
    </>
  );
};

export default MessageContainer;
